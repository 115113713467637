import { httpGet } from '@/api';
import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { errorMessage, loadPage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';

export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入任务名称',
    },
    {
      type: 'input',
      prop: 'principal',
      value: '',
      placeholder: '请输入负责人',
    },
    {
      type: 'multiSelect',
      prop: 'source',
      value: [],
      options: 'event_type',
      width: 180,
      placeholder: '请选择相关事件1',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入相关事件2',
    },
    {
      type: 'multiSelect',
      prop: 'status',
      value: [],
      options: 'task_status',
      width: 180,
      placeholder: '请选择状态',
    },
    {
      type: 'input',
      prop: 'businessNo',
      value: '',
      placeholder: '请输入商机编号',
    },
    {
      type: 'input',
      prop: 'businessCustomerName',
      value: '',
      placeholder: '请输入客户',
    },
    {
      type: 'time',
      prop: ['deadlineStart', 'deadlineEnd'],
      value: [],
      placeholder: ['截止开始日期', '截止结束日期'],
    },
    {
      type: 'multiSelect',
      prop: 'priority',
      value: [],
      options: 'task_priority',
      width: 180,
      placeholder: '请选择优先级',
    },
    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['updateStartDate', 'updateEndDate'],
      value: [],
      placeholder: ['创建开始日期', '创建结束日期'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '任务名称',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.name}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = (row) => {
            Instance.setupState.setRowData(row);
            Instance.setupState.setFormType(3);
            Instance.setupState.showVisible();
          };
          return { clickFn };
        },
      }),
      prop: 'name',
      minWidth: 140,
    },
    {
      label: '负责人',
      prop: 'principalList',
      minWidth: 140,
    },
    {
      label: '相关事件1',
      prop: 'sourceName',
      minWidth: 140,
    },
    {
      label: '相关事件2',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.customerName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = async (row) => {
            const { source, eventId } = row;
            const urlArr = [
              `/malicrm/leads/leadsDetail/${eventId}`,
              `/malicrm/customer/customerDetail/${eventId}`,
              `/malicrm/business/businessDetail/${eventId}`,
            ];
            const detailArr = [
              'viewClue',
              'ContactDetails',
              'OpportunityDetail',
            ];
            const res = await httpGet(urlArr[source - 1]);
            if (res.code === 200) {
              let type = 0;
              if (source === 1) {
                if (row.isDeleted) {
                  type = 3;
                } else {
                  if (row.isInPool) {
                    type = 2;
                  } else {
                    type = 1;
                  }
                  if (row.isTransformed) {
                    type = 4;
                  }
                }
              }
              if (source === 1 || source === 2) {
                if (row.isDeleted) {
                  type = 3;
                } else {
                  if (row.isInPool) {
                    type = 2;
                  } else {
                    type = 1;
                  }
                  if (row.isTransformed) {
                    type = 4;
                  }
                }
              }
              let isRecycle = false;
              if (source === 3) {
                if (row.isDeleted) {
                  isRecycle = true;
                }
              }
              loadPage(detailArr[source - 1], {
                id: eventId,
                type,
                isRecycle,
                status: row.status,
                backFrom: '/office/officeList',
              });
            } else {
              errorMessage(res.message);
            }
          };
          return { clickFn };
        },
      }),
      prop: 'customerName',
      minWidth: 140,
    },
    {
      label: '商机编号',
      prop: 'businessNo',
      minWidth: 140,
    },
    {
      label: '客户',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)" v-if="row.source === 3">
              {{row.businessCustomerName}}
          </div>
          <div v-else>-</div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = (row) => {
            console.log('row', row);
          };
          return { clickFn };
        },
      }),
      prop: 'businessCustomerName',
      minWidth: 140,
    },
    {
      label: '状态',
      prop: 'statusName',
      minWidth: 100,
    },
    {
      label: '截止日期',
      component: defineComponent({
        template: `
          <div  :style="getFontColor(row)">
              {{row.deadline}}
          </div>
        `,
        props: ['row'],
        setup() {
          const getFontColor = (row) => {
            let res = '';
            if (row && row.isChangeColor && row.isChangeColor === true) {
              res = 'color:#E93939';
            }
            return res;
          };
          return { getFontColor };
        },
      }),
      prop: 'deadline',
      minWidth: 140,
    },
    {
      label: '优先级',
      prop: 'priorityName',
      minWidth: 140,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 140,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 180,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
