
import { defineComponent, ref, getCurrentInstance, computed } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import ViewTaskForm from '@/views/crm/clue/components/ViewTask/form.vue';
import useListData from './useOfficeListData';
import useVisible from '@/hooks/useVisible';
import { erpConfirm, exportFile, loadPage } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'TaskList',
  components: { CrmList, ViewTaskForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType } = useVisible();
    const rowData = ref({});
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      loadPage('', {
        id: row.id,
        type: 1, // 1客户 2池 3回收站
      });
    };
    const setFormType = (type: number): void => {
      formType.value = type;
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    const closeVisible = () => {
      visible.value = false;
      // 解除重复提交
    };
    const scoreName = computed(() => {
      let res = (rowData.value as any).customerName;
      if ((rowData.value as any).contactsName !== null) {
        res += '(' + (rowData.value as any).contactsName + ')';
      }
      return res;
    });
    // 导出
    const exportFn = () => {
      erpConfirm('确定导出任务数据吗？').then(async () => {
        exportFile({
          type: 'POST',
          url: '/malicrm/task/exportTask',
          data: {},
        });
      });
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
      setRowData,
      exportFn,
      scoreName,
    };
  },
});
